@mixin social(){
    list-style-type:none;
    margin:0;
    padding:0;
    li {
        display:inline-block;

        i {
            margin-right:rem(5);
            width:rem(32);
            height:rem(32);
            background-color:#fff;
            text-align:center;
            line-height:rem(32);
            font-size:rem(24);
            border-radius:50%;
        }

        a {
            color: $site-color-1;
            text-decoraton: none;
        }
    }
}