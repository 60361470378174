.header{
	.slogan {
		h2 {
			text-align: left;
			padding-top: 10px;

			@media(max-width:766px){
				text-align:center;
				float:none !important;
				padding-left:0;
			}
		}
	}
	.xlvets-farm {
		padding-top: 10px;
		font-size:rem(15);

		img {
			height: 40px;
		}
	}
	.emergency-contact {
		@media(min-width:766px){
			font-size:rem(24);
		}
	}
    h1{
        font-weight:normal;
        margin:0;
        padding:0;
        font-size:rem(24);
        color:$site-color-1;
        margin-bottom:rem(15);
        @media(max-width:990px){
            font-size:rem(20);
        }
        @media(max-width:766px){
            display:none;
        }
    }
    h2{
        font-weight:normal;
        font-size:rem(20);
        color:$site-color-2;
        margin:0;
        padding:0;
		width: 100%;
		text-align: right;
        strong{
            font-weight:normal;
            color:$site-color-3;
            @media(max-width:371px){
                display:block;
                margin-top:rem(15);
            }
        }
        span{
            position:relative;
        }
        i{
            background-image:url('../img/mobile.png');
            width:rem(24);
            height:rem(24);
            display:inline-block;
            background-size:cover;
            position:absolute;
            bottom:rem(0);
            left:rem(-30);
        }
        @media(max-width:766px){
            text-align:center;
            float:none !important;
            padding-left:rem(24);
        }
    }
    .header-top{
        background-color:$site-color-1;
        color:#fff;
        padding-top:rem(15);
        padding-bottom:rem(15);
        p{
            margin:0;
            padding:0;
            i{
                margin-right:rem(5);
            }
            a{
                color:#fff;
                text-decoration:none;
            }
        }
        ul{
            &.secondary-menu{
                margin:0;
                padding:0;
                margin-right:rem(15);
                li{
                    display:inline-block;
                    margin-right:rem(15);

                    &:last-child{
                        margin-right:0;
                    }
                    a{
                        color:#fff;
                        text-decoration:none;
                    }
                }
            }
            &.social{
                @include social();

                li{
                    font-size:rem(18);
                    line-height:rem(24);
                    float: left;
                    margin-right: rem(15);

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        span {
                            color: #fff;
                            font-size: rem(12);
                        }
                    }
                }
            }
        }
        @media(max-width:350px){
            .header-top-left{
                width:100%;
                text-align:center;
                float:none;
            }
            .header-top-right{
                display:none;
            }
        }
    }
    .header-middle{
        padding-top:rem(30);
        padding-bottom:rem(30);
        @media(max-width:766px){
            text-align:center;
        }
        .header-middle-left{
            a{
                display:block;
                img{
                    @include responsive();
                    @media(max-width:766px){
                        max-width:50% !important;
                        width:50% !important;
                        min-width:50% !important;
                    }
                    @media(max-width:414px){
                        max-width:90% !important;
                        width:90% !important;
                        min-width:90% !important;
                    }
                }

            }
        }
        .header-middle-right{
            padding-top:rem(25);
            @media(max-width:990px){
                padding-top:rem(15);
            }
        }
    }
    .header-bottom{
        .line{
            border-bottom:solid rem(1) $site-color-2;
        }
    }
}