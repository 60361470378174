.main{
    &.page{
        h1,h2,h3{
            margin:0;
            padding:0;
            margin-bottom:rem(15);
        }
        h1{
            font-size:rem(24);
            color:$site-color-1;
        }
        h2{
            font-size:rem(20);
            color:$site-color-1;
        }
        h3{
            font-size:rem(18);
            color:$site-color-2;
        }  
        .line{
            border-bottom:solid rem(1) $site-color-2;
        }
        .body{
            padding-top:rem(30);
            padding-bottom:rem(30);
        }
    }
}