.block-system{
    .form-item{
        @extend .form-group;
        input[type="text"],
        input[type="email"],
        input[type="password"],
        textarea{
            @extend .form-control;
        }
    }
    .form-submit{
        @extend .btn;
        @extend .btn-primary;
    }
}

//Front page news block
#block-views-news-block-2 {
	.row {
		margin-bottom: 15px;
		
		h4 {
			margin-top: 0;
		}
	}
}