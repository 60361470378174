.block-search{
    form{
        @extend .navbar-form;
        @extend .navbar-right;       
    }
    .form-actions{
        display:none;
    }
    .form-text{
        @extend .form-control;
        border-radius:0;
        background-image:url('../img/search.png');
        background-repeat:no-repeat;
        background-position: center right;
    }
}