@import "container";
@import "paragraphs/index";
@import "blocks/index";

//Factsheets page

.factsheet-list {
		.views-row {
			.well {
				height: 210px;
			}
		}

	.factsheet-image {
			min-height: 142px;
			text-align: center;
	}
}