// Fonts
$font-family-sans-serif: "Open Sans", sans-serif;

// Brand
$brand-primary: $site-color-2;

// Buttons
$btn-border-radius-base:0;

// Navbar
$navbar-border-radius:0;
$navbar-default-bg: transparent;
$navbar-margin-bottom: 0;

// Navbar links
$navbar-default-link-color:                $site-color-2;
$navbar-default-link-hover-color:          $site-color-1;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         $text-color;
$navbar-default-link-active-bg:            transparent;
$navbar-default-link-disabled-color:       #ccc;
$navbar-default-link-disabled-bg:          transparent;