.footer{
    background-color:$site-color-1;
    padding-top:rem(30);
    padding-bottom:rem(30);
    position:absolute;
    left:0;
    bottom:0;
    width:100%;
    @media(max-width:767px){
        text-align:center;
    }
    ul{
        &.social{
            @include social();
        }
    }
    p{
        color:#fff;
        font-size:rem(11);
        @media(max-width:767px){
            text-align:center !important;
        }
        a{
            color:#fff;
            text-decoration:none;
        }
        &.copyright{
            margin-top:rem(15);
            margin-bottom:rem(5);
        }
        &.website{
            margin-bottom:0;
        }
        &.address, 
        &.telephone{
            text-align:right;
        }
        @media(max-width:767px){
            &.telephone{
                margin-bottom:rem(15);
            }
        }
        &.logo{
            text-align:right;
            margin-bottom:rem(15);
            img{
                width:rem(130);
                @media(max-width:767px){
                    width:rem(150);
                }
            }
        }
    }
}