// Fix for bootstrap
$em-base: 14px;

// Colours
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$text-color: $gray-dark;

// Dark blue
$site-color-1:#272d6d;

// Lighter blue from logo
$site-color-2:#368bc9;

// Dark grey
$site-color-3: #666;

// Light blue
$site-color-4: #ebf3f9;