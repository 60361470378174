.paragraphs-item-services{
    padding-bottom:rem(30);    
    h1{
        margin:0;
        padding:0;
        text-transform:uppercase;
        font-size:rem(24);
        padding-top:rem(15);
        padding-bottom:rem(15);   
        text-align:center;
        padding-left:rem(15);
        padding-right:rem(15);
        background-color:#fff;
        display:inline-block;
        margin-left:auto;
        margin-right:auto;
    }
    .bar{
        background-image:url('../img/seperator.png');
        background-repeat:repeat-x;
        text-align:center;
    }    
    article{
        @media(max-width:767px){
            margin-bottom:rem(15);
            &:last-child{
                margin-bottom:0;
            }
        }
        h1{
            text-transform:capitalize;
            color:$site-color-1;
            display:block;
        }
        .line{
            text-align:center;
            display:block;
            width:rem(50);
            border-bottom:solid rem(2) $site-color-2;
            margin-left:auto;
            margin-right:auto;
            margin-bottom:rem(15);
        }
        img{
            @include responsive();
        }
        p{
            text-align:center;
            color:#666;
            margin-bottom:0;
            &:last-child{
                margin-top:rem(15);
            }
        }
        a{
            &.btn-primary{
                &:after{
                    content:"\f105";
                    font-family:fontawesome;
                    margin-left:rem(5);
                }
            }
        }
    }
}