.paragraphs-item-case-studies-news{
    h1{
        margin:0;
        padding:0;
        text-transform:uppercase;
        font-size:rem(24);
        padding-top:rem(15);
        padding-bottom:rem(15);   
        padding-right:rem(15);
        background-color:#fff;
        display:inline-block;
        a{
            color:$site-color-1;
            text-decoration:none;
        }
    }    
    .bar{
        background-image:url('../img/seperator.png');
        background-repeat:repeat-x;
    }
    .link{
        color:$site-color-2;
        &:after{
            content:"\f105";
            font-family:fontawesome;
            margin-left:rem(5);
        }
    }
    .line{
        text-align:left;
        display:block;
        width:rem(50);
        border-bottom:solid rem(2) $site-color-2;
        margin-bottom:rem(15);
    }   
    article{
        &.study{
            margin-bottom:rem(30);   
            &:last-child{
                margin-bottom:0;
            }
            h1{
                text-transform:capitalize;
                color:$site-color-1;
                display:block;
                margin:0;
                padding:0;
                margin-bottom:rem(15);
                color:$site-color-1;
                line-height:1.2;
            }
            p{
                &:last-child{
                    margin-bottom:0;
                }
            }
            @media(max-width:767px){
                img{
                    margin-bottom:rem(15);
                }
            }
        }
        &.news{
            margin-bottom:rem(30);   
            padding-left:rem(15);
            padding-right:rem(15);
            &:last-child{
                margin-bottom:0;
            }
            .wrapper{
                background-color:$site-color-4;
                padding:rem(15);
            }
            .news-left{
                padding-right:0;
                @media(max-width:990px){
                    width:100%;
                    float:none;
                    padding-right:rem(15);
                    img{
                        margin-bottom:rem(15);
                    }
                }
            }
            @media(max-width:990px){
                .news-right{
                    width:100%;
                    float:none;
                }
            }
            
            h1{
                text-transform:capitalize;
                background-color:transparent;
                margin:0;
                padding:0;
                font-size:rem(18);
                margin-bottom:rem(15);  
                color:$site-color-1;
                border-bottom:solid rem(1) $site-color-2;
                display:block;
                padding-bottom:rem(15);
            }
            h2{
                text-transform:capitalize;
                margin:0;
                padding:0;
                font-size:rem(16);
                margin-bottom:rem(15);
                color:$site-color-1;
                a{
                    color:$site-color-1;
                    text-decoration:none;
                }
            }
            p{
                &:last-child{
                    margin-bottom:0;
                }
            }
        }
    }
    img{
        @include responsive();
    }    
}