.paragraphs-item-banner{
    .banner{
        width:100%;
        height:rem(300);
        overflow:hidden;
        position:relative;
        h1{
            position:absolute;
            bottom:rem(15);
            left:rem(15);
            color:#fff;
            margin:0;
            padding:0;
            font-size:rem(24);
            display:inline-block;
            width:20%;
            @media(max-width:1193px){
                width:23%;
            }
            @media(max-width:990px){
                width:30%;
            }
            @media(max-width:667px){
                width:35%;
            }
            @media(max-width:583px){
                font-size:rem(22);
            }
            @media(max-width:531px){
                width:40%;
            }
            @media(max-width:467px){
                font-size:rem(20);
            }
            @media(max-width:426px){
                width:45%;
            }
            @media(max-width:382px){
                font-size:rem(18);
                width:60%;
            }
        }
    }
}