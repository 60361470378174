.navbar-default {
    @media(min-width:768px){
        .navbar-collapse{
            padding-left:0;
            padding-right:0;
        }
        .navbar-nav{
            &>li{
               &>a{
                padding-left:0;
               }
            }
        }
    }

    .nav {
        a {
            text-transform: none;
        }
    }

    .dropdown-menu {
        li {
            list-style: none;
        }
    }
}