.nav{
    a{
        text-transform:uppercase;
        color:$site-color-3;
    }
    .nav-left{
        padding-top:rem(15);
        padding-bottom:rem(15);
    }
    .nav-right{
        padding-top:rem(8);
        @media(max-width:766px){
            display:none;
        }
    }
}

.region-legal-menu {
    .navbar-nav {
        margin: 0 0 25px 0;

        > li {
            padding: 0;
            font-size: $font-size-base * 0.9;
            margin-right: 10px;

            a {
                text-transform: none;
                padding: 5px 10px;

                &:link,
                &:visited,
                &:hover,
                &:active {
                    color: #fff;
                }

                &:hover {
                    text-decoration: underline;
                    background-color: transparent;
                    opacity: 0.7;
                }

            }

            &.first {
                a {
                    padding-left: 0;
                }
            }

            &.last {
                margin-right: 0;

                a {
                    padding-right: 0;
                }
            }
        }
    }
}