.paragraphs-item-map{
    h1{
        margin:0;
        padding:0;
        text-transform:uppercase;
        font-size:rem(24);
        padding-top:rem(15);
        padding-bottom:rem(15);
        background-color:#fff;
        display:inline-block;
        padding-right:rem(15);
    }
    .bar{
        background-image:url('../img/seperator.png');
        background-repeat:repeat-x;
    }
    .map{
        background-size:cover;
        width:100%;
        min-height:rem(300);
        background-position: center center;
    }
}