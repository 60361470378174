.paragraphs-item-welcome{
    background-color:$site-color-1;    
    padding-top:rem(30);
    padding-bottom:rem(30);
    h1{
        color:#fff;
        margin:0;
        padding:0;
        text-align:right;
        font-size:rem(24);
        font-weight:600;
        padding-left:rem(20);
        @media(max-width:500px){
            text-align:center;
            padding-left:0;
            width:80%;
            margin-left:auto;
            margin-right:auto;
        }
    }
    p{
        color:#fff;
        margin-bottom:rem(15);
        @media(max-width:500px){
            text-align:center;
        }        
    }
    .line{
        display:block;
        width:rem(50);
        border-bottom:solid rem(2) $site-color-2;
        margin-top:rem(15);
        float:right;
        @media(max-width:500px){
            float:none;
            margin-left:auto;
            margin-right:auto;
            margin-bottom:rem(15);
        }
    } 
    a{
        &.btn-primary{
            &:after{
                content:"\f105";
                font-family:fontawesome;
                margin-left:rem(5);
            }
        }
    }
    @media(max-width:500px){
        .welcome-left,
        .welcome-right{
            width:100%;
            float:none;
        }
    }
}