body{
    margin-bottom:rem(177);
}

//Meet the team page
.list-meet-the-team {
		.views-row {
			.well {
				height: 235px;
				img {
					@extend .img-rounded;
				}
			}
		}
}

//Gallery Block
.view-gallery-block .views-row {
	margin-bottom: 15px;
	margin-left: -13px;
}

//Basic page files table
.field-name-field-files {
	table.sticky-table {
		@extend .table;
		@extend .table-striped;
		@extend .table-hover;
	}
}